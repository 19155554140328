import useSWR from 'swr';

/** Utils / Const */
import { bffFetcher } from 'utils/functions';

const useCampaignBanner = () => {
  const { data: campaignBanner, error } = useSWR('/campaign-banner', bffFetcher);

  return {
    error,
    campaignBanner,
    isLoading: !error && !campaignBanner,
  };
};

export default useCampaignBanner;
