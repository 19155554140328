import PropTypes from 'prop-types';

// Material-UI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Components
import Carousel from 'components/Carousels/home/GlideCarousel';

// Hooks
import { useTheme } from '@mui/material/styles';
import useResponsive from 'hooks/useResponsive';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: { sx: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' },
    width: '100%',
    backgroundColor: 'white',
    pt: { xs: 1, sm: 1, md: 1.5, lg: 2, xl: 2 },
    fontSize: { xs: 1.5, sm: 1.5, md: 1.5, lg: 2, xl: 1.5 },
    textAlign: { xs: 'center', sm: 'center', md: 'start', lg: 'start', xl: 'start' },
  },
};

const BlogPostTitle = () => {
  return (
    <Typography component="h3" variant="h5" fontWeight="600">
      THE BLOG - BUYING AND SELLING WHOLESALE
    </Typography>
  );
};

const blogPostSectionDefaultPropBlogPostData = [
  {
    id: -1,
    name: '',
    url: '',
  },
];

const BlogPostSection = ({ blogPostData = blogPostSectionDefaultPropBlogPostData }) => {
  const theme = useTheme();
  const isMobile = useResponsive('down', 'sm');

  return (
    <Container maxWidth="xl" disableGutters={isMobile} sx={{ mb: 3 }}>
      <Box sx={styles.root}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            px: { xs: 1, sm: 4 },
            mb: 1,
            mt: { xs: 1, sm: 1, md: 2 },
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            },
          }}
        >
          <BlogPostTitle />
          <Link
            data-testid="view-all-link"
            color="primary"
            type="inline"
            variant="body2"
            href="https://www.creoate.com/blog/"
            sx={{
              [theme.breakpoints.down('sm')]: {
                mt: 0.5,
              },
            }}
            passHref
          >
            View All
          </Link>
        </Box>
        <Carousel carouselData={blogPostData} />
      </Box>
    </Container>
  );
};

BlogPostSection.propTypes = {
  blogPostData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export default BlogPostSection;
