import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';

const HomePageSeo = ({
  title = 'Creoate - Online Wholesale Marketplace for Independent Retailers and Brands',
  description = 'Discover and buy unique wholesale products online at Creoate. Shop wholesale homeware, home decor products, jewellery, fashion accessories, stationery, gifts, food, drinks, kids and baby products etc. from thousands of independent wholesale vendors.',
}) => {
  const SpeakableSchemaJsonLd = {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    name: 'Wholesale Marketplace for Independent Retailers and Brands',
    speakable: {
      '@type': 'SpeakableSpecification',
      xPath: ['/html/head/title', "/html/head/meta[@name='description']/@content"],
    },
    url: 'https://www.creoate.com/',
  };
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta property="og:locale" content="en_GB" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content="https://www.creoate.com/" />
        <meta property="og:site_name" content="CREOATE" />
        <meta
          property="og:image"
          content="https://creoateprod.s3.eu-west-2.amazonaws.com/wp-content/uploads/2020/03/05121722/cropped-final-logo-black-2.png"
        />
        <meta property="og:image:width" content="472" />
        <meta property="og:image:height" content="110" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:site" content="@creoateuk" />
        <meta name="twitter:creator" content="@creoateuk" />

        <link rel="canonical" href="https://www.creoate.com/" />

        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

        <meta name="google-site-verification" content="cM0g1njWHAic9T6aQUJJr0okSdXavP6u8L2xt32esCk" />
      </Head>

      <script
        id="seo-script-SpeakableSpecification"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(SpeakableSchemaJsonLd),
        }}
      />

      <script
        id="seo-script-SearchAction"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "url": "https://www.creoate.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://creoate.com/?s={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }`,
        }}
      />

      <script
        id="seo-script-FAQPage"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What does CREOATE do?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "CREOATE is a wholesale marketplace. We provide a platform for independent retailers to source stock from independent brands, without the cost and hassle of traditional methods. Order minimums are set by each brand, and start at just £75. CREOATE doesn’t hold stock. When you place an order through our website, you’re buying directly from that brand."
              }
            },{
              "@type": "Question",
              "name": "Who can shop on CREOATE?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can only shop on CREOATE if you are a business looking to shop wholesale for resale, and we have a few questions in place at signup to check this."
              }
            },{
              "@type": "Question",
              "name": "What is wholesale?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Wholesale refers to the practice of retailers buying goods directly from suppliers or brands, and selling them on to the end consumer — essentially, wholesale for resale. While it can also refer to buying goods ‘in bulk’ at a lower price for personal consumption, here, we’ll always be talking about the first meaning. Find out more."
              }
            },{
              "@type": "Question",
              "name": "How do I see wholesale prices?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "If you’re a retailer, you can view the wholesale price of any item by creating an account. It’s quick and easy. Create an account."
              }
            },{
              "@type": "Question",
              "name": "How does shipping work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "If you spend £200 (excluding VAT), your entire order to mainland UK will get free shipping (exclusions may apply, you will be notified at check out). For Europe, it’s orders of €300 or more (excluding VAT), and for the US it’s orders of $300 or more (excluding VAT). Not spending that much? Don’t worry. We offer a flat rate fee per supplier for the UK of £8.99, rest of Europe £15.99 and USA £24.99. If you’re selling on CREOATE, you’re in luck; we take care of shipping for you, free of charge. Just print your label and book a collection slot."
              }
            },{
              "@type": "Question",
              "name": "Which countries does CREOATE ship to?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We currently facilitate shipping to the UK, Europe and the US. More coming soon!"
              }
            },{
              "@type": "Question",
              "name": "How does Buy Now Pay Later work?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "CREOATE helps you shop wholesale at a rhythm that works for retail. We offer a trade credit limit of up to £2,500, and the option to pay up to 60 days after you order, all with the aim of helping your cash flow. Buy Now Pay Later is optional; If you’d rather, you can always pay for your order upfront."
              }
            }
            ]
          }
          `,
        }}
      />
    </>
  );
};

HomePageSeo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default HomePageSeo;
