import PropTypes from 'prop-types';

// Material-UI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Hooks
import useAuth from 'hooks/useAuth';

// components
import Carousel from 'components/Carousels/home/GlideCarousel';

const CATEGORIES = [
  {
    id: '1',
    name: `Women's Clothing`,
    url: '/product-category/women-clothing',
    image: '/assets/images/homepage/categories/womens fashion1.webp',
  },
  {
    id: '2',
    name: 'Home & Garden',
    url: '/product-category/home-garden',
    image: '/assets/images/homepage/categories/home&garden.webp',
  },
  {
    id: '3',
    name: 'Stationery',
    url: '/product-category/stationery',
    image: '/assets/images/homepage/categories/stationery 3.webp',
  },
  {
    id: '4',
    name: 'Bath & Body',
    url: '/product-category/bath-and-body',
    image: '/assets/images/homepage/categories/body&bath.webp',
  },
  {
    id: '5',
    name: 'Kitchen',
    url: '/product-category/kitchen',
    image: '/assets/images/homepage/categories/kitchen 3.webp',
  },
  {
    id: '6',
    name: 'Accessories',
    url: '/product-category/accessories',
    image: '/assets/images/homepage/categories/accessories.webp',
  },
  {
    id: '7',
    name: 'Gourmet food',
    url: '/product-category/gourmet-food',
    image: '/assets/images/homepage/categories/gourmet food 1.webp',
  },
  {
    id: '8',
    name: 'Beauty',
    url: '/product-category/beauty',
    image: '/assets/images/homepage/categories/beauty.webp',
  },
  {
    id: '9',
    name: 'Candles',
    url: '/product-category/candles',
    image: '/assets/images/homepage/categories/candle 1.webp',
  },
  {
    id: '10',
    name: 'Toys & Games',
    url: '/product-category/kids',
    image: '/assets/images/homepage/categories/kids 1.webp',
  },
  {
    id: '11',
    name: 'Jewellery',
    url: '/product-category/jewellery',
    image: '/assets/images/homepage/categories/jewellery 1.webp',
  },
  {
    id: '12',
    name: 'Pet Supplies',
    url: '/product-category/pet-supplies',
    image: '/assets/images/homepage/categories/pet 2.webp',
  },
];

const styles = {
  auth: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: { sx: 'center', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' },
    width: '100%',
    backgroundColor: '#749B8D',
    pt: { xs: 1, sm: 1, md: 1.5, lg: 2, xl: 2 },
    fontSize: { xs: 1.5, sm: 1.5, md: 1.5, lg: 2, xl: 1.5 },
    color: 'white',
    textAlign: { xs: 'center', sm: 'center', md: 'start', lg: 'start', xl: 'start' },
  },
  unAuth: {
    mt: 2,
  },
};

const Title = () => (
  <Typography
    sx={{ px: { xs: 1, sm: 5.625 }, mb: { xs: 1, sm: 1, md: 0.6 }, mt: { xs: 1, sm: 1, md: 2 } }}
    component="h2"
    variant="h5"
  >
    Shop 300,000+ products from our independent brands
  </Typography>
);

const WelcomeMessage = ({ user }) =>
  user?.displayName ? (
    <Typography sx={{ px: { xs: 1, sm: 5.625 } }} component="h2" variant="body1">
      Welcome to Creoate, {user?.firstName} {user?.lastName}
    </Typography>
  ) : (
    <Typography sx={{ px: { xs: 1, sm: 5.625 } }} component="h2" variant="body1">
      Welcome to Creoate, {user?.displayName}
    </Typography>
  );

WelcomeMessage.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

const CategoriesCarousel = () => {
  const { isAuthenticated, user } = useAuth();

  return (
    <Box>
      <Box sx={isAuthenticated ? styles.auth : styles.unAuth}>
        {isAuthenticated && (
          <>
            <WelcomeMessage user={user} />
            <Title />
          </>
        )}
        <Carousel carouselData={CATEGORIES} textColor={isAuthenticated ? 'white' : 'black'} reference="categories" />
      </Box>
    </Box>
  );
};

CategoriesCarousel.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
  }),
};

export default CategoriesCarousel;
