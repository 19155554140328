import { useEffect, useState } from 'react';

// Material-UI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Hooks
import useResponsive from 'hooks/useResponsive';
import useNewInSection from 'hooks/useNewInSection';

// Components
import ProductCardGlideCarousel from 'components/Carousels/core/ProductCardGlideCarousel';
import { productCarouselSettings } from 'utils/carousels';

const Title = () => {
  return (
    <Typography component="h3" variant="h5" fontWeight="600">
      RECENTLY ADDED
    </Typography>
  );
};

const RecentlyAdded = () => {
  const isMobile = useResponsive('down', 'sm');
  const [products, setProducts] = useState([]);
  const [rendered, setRendered] = useState(false);
  const { data, isLoading } = useNewInSection({ limit: 4, offset: 4, isRemainingProducts: rendered });

  useEffect(() => {
    if (!rendered && data?.products?.length) {
      setProducts(data.products);
      setRendered(true);
    }
  }, [rendered, data?.products]);

  useEffect(() => {
    if (rendered && data?.products?.length) {
      setProducts((prevProducts) => [...prevProducts, ...data.products]);
    }
  }, [rendered, data?.products]);

  return (
    <Container maxWidth="xl" disableGutters={isMobile} sx={{ mb: 3 }}>
      <ProductCardGlideCarousel
        sx={{ mt: 3 }}
        products={rendered ? products : data?.products}
        isLoading={!rendered && isLoading}
        title={<Title />}
        carouselSettings={productCarouselSettings}
      />
    </Container>
  );
};

export default RecentlyAdded;
