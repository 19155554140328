import { Typography, Box, Container, Button, styled } from '@mui/material';

// Components
import Link from 'components/Link';
import FaqList from 'components/FaqList';

// Constants
import { HOMEPAGE_FAQ_UNREGISTERED_REF } from 'constants/route-refs';

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  backgroundColor: 'theme.palette.background.default',
}));

const FAQHome = () => {
  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Box sx={{ mb: { xs: 3, md: 2 } }}>
          <Typography
            variant="h3"
            sx={{ textAlign: 'center', fontSize: { xs: 24, md: 34 }, fontWeight: { xs: 'bold' } }}
          >
            FAQ
          </Typography>
        </Box>
        <FaqList />
        <Button
          component={Link}
          color="primary"
          type="inline"
          variant="contained"
          href={`https://helpcenter.creoate.com?ref=${HOMEPAGE_FAQ_UNREGISTERED_REF}/`}
          target="_blank"
          sx={{
            maxWidth: '300px',
            textAlign: 'center',
            marginTop: '2rem',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          View More
        </Button>
      </Container>
    </RootStyle>
  );
};

export default FAQHome;
