import Head from 'next/head';

const OrganizationSchemaSeo = () => {
  return (
    <Head>
      <script
        id="Organization-Schema-Seo"
        type="application/ld+json"
        data-turbolinks-track="reload"
        dangerouslySetInnerHTML={{
          __html: `{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name" : "Creoate - Wholesale Marketplace for Independent Retailers and Brands",
            "legalName": "Creoate",
            "url": "https://www.creoate.com/",
            "logo": "https://creoateprod.s3.eu-west-2.amazonaws.com/wp-content/uploads/2020/03/05121722/cropped-final-logo-black-2.png",
            "address": [{
              "@type": "PostalAddress",
              "streetAddress": "70 Clifton Street",
              "addressLocality": "London",
              "postalCode": "EC2A 4HB",
              "addressCountry": "GB"
            }],
            "sameAs": [
              "https://www.facebook.com/creoate/",
              "https://twitter.com/creoateuk",
              "https://www.instagram.com/creoate/",
              "https://www.linkedin.com/company/creoate/",
              "https://www.youtube.com/@creoate6896",
              "https://www.pinterest.co.uk/creoate/",
              "https://www.tiktok.com/discover/creoate"
            ]
          }`,
        }}
      />
    </Head>
  );
};

export default OrganizationSchemaSeo;
