/**
 * this carousel is used in
 * 1. for-you page brands tab
 * 2. home page
 */
import PropTypes from 'prop-types';

// Material-UI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Hooks
import { useTheme } from '@mui/material/styles';
import useResponsive from 'hooks/useResponsive';
import useMoversShakersSection from 'hooks/useMoversShakersSection';

// Components
import BrandCardGlideCarousel from 'components/Carousels/core/BrandCardGlideCarousel/BrandCardGlideCarousel';

const Title = () => {
  const theme = useTheme();

  return (
    <>
      <Typography variant="h6" fontWeight="700">
        Movers & Shakers
      </Typography>
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            mt: theme.spacing(0.5),
          },
        }}
      >
        These brands are selling very fast. They will soon become best sellers
      </Typography>
    </>
  );
};

const MoversShakersSection = ({ currentTab, showOnTab = 'brands', carouselSettings }) => {
  const isMobile = useResponsive('down', 'sm');
  // we cannot paginate here!!
  const { data, error, isLoading } = useMoversShakersSection();

  if (error || currentTab !== showOnTab || data?.brands?.length < 4) return null;

  return (
    <Container maxWidth="xl" disableGutters={isMobile} sx={{ mb: 3 }}>
      <BrandCardGlideCarousel
        sx={{ mt: 3 }}
        brands={data?.brands}
        isLoading={isLoading}
        title={<Title />}
        viewMoreUrl="/new-in?ref=brands_all"
        carouselSettings={carouselSettings}
      />
    </Container>
  );
};

export default MoversShakersSection;

MoversShakersSection.propTypes = {
  showOnTab: PropTypes.string,
  currentTab: PropTypes.string.isRequired,
  carouselSettings: PropTypes.shape({}).isRequired,
};
