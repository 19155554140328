import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { forwardRef, useEffect, useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
// components
import Seo from 'components/Seo';
// utils
import track from 'utils/analytics';

// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const { pathname } = useRouter();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box ref={ref} {...other}>
      <Seo title={title} />
      {children}
    </Box>
  );
});

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Page;
