import { DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';
import useSWR from 'swr';
import { bffFetcher } from 'utils/functions';
import useAuth from './useAuth';

const DEFAULT_LIMIT = 16;
const DEFAULT_BRAND_MIN_PRODUCTS = 50;

const useMoversShakersSection = ({ limit = DEFAULT_LIMIT, brandMinProducts = DEFAULT_BRAND_MIN_PRODUCTS } = {}) => {
  const auth = useAuth();

  const shippingCountryCode = auth?.user?.shippingCountry;

  const params = new URLSearchParams({
    sortBy: DEFAULT_SORT_BY_VALUE,
    limit,
    brandMinProducts,
    shippingCountryCode,
  });

  const { data, error, isValidating } = useSWR(`/brands?${params}`, bffFetcher);

  return {
    data,
    error,
    isLoading: isValidating,
  };
};

export default useMoversShakersSection;
