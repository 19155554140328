/**
 * this carousel is used in
 * 1. for-you page products tab
 * 2. home page
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Material-UI
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

// Hooks
import { useTheme } from '@mui/material/styles';
import useResponsive from 'hooks/useResponsive';
import useNewInSection from 'hooks/useNewInSection';

// Components
import ProductCardGlideCarousel from 'components/Carousels/core/ProductCardGlideCarousel';

const Title = () => {
  const theme = useTheme();
  return (
    <>
      <Typography variant="h6" fontWeight="700">
        New products
      </Typography>
      <Typography
        sx={{
          [theme.breakpoints.down('sm')]: {
            mt: theme.spacing(0.5),
          },
        }}
      >
        Discover thousands of products and get 60-day returns on first orders
      </Typography>
    </>
  );
};

const NewInSection = ({ currentTab, showOnTab = 'products', carouselSettings }) => {
  const isMobile = useResponsive('down', 'sm');
  const [products, setProducts] = useState([]);
  const [rendered, setRendered] = useState(false);
  const { data, error, isLoading } = useNewInSection({ limit: 4, offset: 4, isRemainingProducts: rendered });

  useEffect(() => {
    if (!rendered && data?.products?.length) {
      setProducts(data.products);
      setRendered(true);
    }
  }, [rendered, data?.products]);

  useEffect(() => {
    if (rendered && data?.products?.length) {
      setProducts((prevProducts) => [...prevProducts, ...data.products]);
    }
  }, [rendered, data?.products]);

  if (error || currentTab !== showOnTab) return null;

  return (
    <Container maxWidth="xl" disableGutters={isMobile} sx={{ mb: 3 }}>
      <ProductCardGlideCarousel
        sx={{ mt: 3 }}
        products={rendered ? products : data?.products}
        isLoading={!rendered && isLoading}
        title={<Title />}
        viewAllUrl="/new-in?tab=products&ref=products_all"
        carouselSettings={carouselSettings}
      />
    </Container>
  );
};

export default NewInSection;

NewInSection.propTypes = {
  showOnTab: PropTypes.string,
  currentTab: PropTypes.string.isRequired,
  carouselSettings: PropTypes.shape({}).isRequired,
};
