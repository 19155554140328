import { CarouselDots } from 'components/carousel';

export const productCarouselSettings = {
  arrows: false,
  lazyLoad: false,
  swipeToSlide: true,
  slidesToShow: 5,
  slidesToScroll: 5,
  centerMode: false,
  centerPadding: '0px',
  dots: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1281,
      settings: { slidesToShow: 4, slidesToScroll: 4 },
    },
    {
      breakpoint: 1160,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: true,
        ...CarouselDots({
          sx: { mt: 3, margin: '0px' },
        }),
      },
    },
  ],
};
