/* eslint-disable react/function-component-definition */
import { useState } from 'react';
// @mui
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// _mock_
import { faqs } from 'constants/faqs';

export default function FaqsList() {
  const [expanded, setExpanded] = useState(false);

  const handleChangeControlled = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      {faqs.map((faq) => (
        <Accordion
          key={faq.id}
          expanded={expanded === faq.value}
          onChange={handleChangeControlled(faq.value)}
          elevation={2}
        >
          <AccordionSummary
            sx={{ justifyContent: 'space-between' }}
            expandIcon={<ExpandMoreIcon width={20} height={20} />}
            aria-controls={faqs.id}
          >
            <Typography variant="subtitle1" fontWeight={700}>
              {faq.heading}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body1" component="div" dangerouslySetInnerHTML={{ __html: faq.detail }} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
}
