import { useRef } from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

// Material-UI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DoubleArrowRounded } from '@mui/icons-material';

// Components
import { CarouselDots } from 'components/carousel';

// Hooks
import useResponsive from 'hooks/useResponsive';
import useReferrals from 'hooks/useReferrals';

// Constants
import WP_ROUTES from 'constants/wordpress-routes';
import { HOMEPAGE_BENEFITS_UNREGISTERED_REF } from 'constants/route-refs';

const CARDS = [
  {
    title: 'Buy now, pay later ',
    description: 'Flexible payment options that work for retailers',
    textButton: 'Register to Buy',
    linkButton: `${WP_ROUTES.register}?ref=${HOMEPAGE_BENEFITS_UNREGISTERED_REF}`,
  },
  {
    title: 'Free global shipping',
    description: 'Free shipping from £300 (T&C apply)',
    textButton: 'Register to Buy',
    linkButton: `${WP_ROUTES.register}?ref=${HOMEPAGE_BENEFITS_UNREGISTERED_REF}`,
  },
  {
    title: 'Easy returns',
    description: 'Send back what doesn’t sell in 60 days',
    textButton: 'Register to Buy',
    linkButton: `${WP_ROUTES.register}?ref=${HOMEPAGE_BENEFITS_UNREGISTERED_REF}`,
  },
  {
    title: 'Price Match Promise',
    description: `Found it cheaper? We'll refund you the difference`,
    textButton: 'Register to Buy',
    linkButton: `${WP_ROUTES.register}?ref=${HOMEPAGE_BENEFITS_UNREGISTERED_REF}`,
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  backgroundColor: '#EDD4D5',
}));

const CardStyle = styled(Card)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 320,
    minHeight: 140,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    boxShadow: 'none',
    backgroundColor: '#EDD4D5',
  };
});

const MobileCarousel = () => {
  const carouselRef = useRef(null);

  const settings = {
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    dots: true,
    ...CarouselDots({
      rounded: true,
      sx: { mt: 3 },
    }),
    centerMode: false,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 480,
        settings: { slidesToShow: 1, slidesToScroll: 1 },
      },
    ],
  };

  /* This code creates a carousel of CollectionCard components, which are displayed using the Slider component. 
  The CarouselArrows component provides the user with the ability to navigate through the carousel using the 
  onNext and onPrevious functions. The position of the carousel is set to relative, and each card is given a 
  padding of 1.5px horizontally and 10px vertically. */
  return (
    <Box sx={{ position: 'relative', maxWidth: '700px', alignContent: 'center' }}>
      <Slider ref={carouselRef} {...settings}>
        {CARDS.map((benefits) => (
          <Box key={benefits.id}>
            <BenefitsCard benefits={benefits} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

const benefitsCardDefaultPropBenefits = {
  title: '',
  description: '',
  textButton: '',
  linkButton: '',
};

const BenefitsCard = ({ benefits = benefitsCardDefaultPropBenefits }) => {
  const { title, description, textButton, linkButton } = benefits;
  const { queryParamsString } = useReferrals();

  return (
    <Grid key={title} item xs={12} sm={12} md={4} lg={3}>
      <CardStyle>
        <Typography variant="h6" paragraph sx={{ fontSize: '24px', fontWeight: 'bold', mb: 0 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ color: 'primary' }}>
          {description}
        </Typography>
        <Box>
          <Button
            variant="text"
            href={`${linkButton}${queryParamsString ? `&${queryParamsString}` : ''}`}
            sx={{ fontWeight: 'bold', mb: '24px' }}
          >
            {textButton} <DoubleArrowRounded />
          </Button>
        </Box>
      </CardStyle>
    </Grid>
  );
};

BenefitsCard.propTypes = {
  benefits: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    textButton: PropTypes.string,
    linkButton: PropTypes.string,
  }),
};

const Benefits = () => {
  const isMobile = useResponsive('down', 'md');
  return (
    <RootStyle>
      {isMobile ? (
        <Container maxWidth="100%">
          <MobileCarousel />
        </Container>
      ) : (
        <Container maxWidth="xxl">
          <Grid
            container
            spacing={2}
            columns={{ md: 8, lg: 12, xl: 12 }}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            {CARDS.map((card) => (
              <BenefitsCard key={card.title} benefits={card} />
            ))}
          </Grid>
        </Container>
      )}
    </RootStyle>
  );
};

export default Benefits;
