// Material-UI
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// Components
import Link from 'components/Link/Link';

// Constants
import WP_ROUTES from 'constants/wordpress-routes';
import { HOMEPAGE_SIGNUPCTA_UNREGISTERED_REF } from 'constants/route-refs';
import useReferrals from 'hooks/useReferrals';

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: 'url(/assets/images/ctas/signUpCTA_BG_mobile.webp)',
  height: 375,
  [theme.breakpoints.up('md')]: {
    backgroundImage: 'url(/assets/images/ctas/SignUpCTA_bg.webp)',
    height: 342,
  },
}));

const ContentStyle = styled('div')(() => ({
  textAlign: 'center',
  display: 'flex',
  direction: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

// ----------------------------------------------------------------------

const signUpCTAData = {
  id: 1,
  title: 'Trusted by over 250,000+ independent retailers',
  subtitle: 'Join the CREOATE community. Sign up to start shopping today',
  buttonTextJoin: 'Join today',
  buttonTextSell: 'Sell on Creoate',
  buttonLinkJoin: `${WP_ROUTES.register}?ref=${HOMEPAGE_SIGNUPCTA_UNREGISTERED_REF}`,
  buttonLinkSell: `${WP_ROUTES.registerWholesaler}?ref=${HOMEPAGE_SIGNUPCTA_UNREGISTERED_REF}`,
};

// ----------------------------------------------------------------------

const SignUpCTA = () => {
  const { queryParamsString } = useReferrals();

  return (
    <RootStyle>
      <ContentStyle>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          sx={{ mx: 1.25, maxWidth: 375 }}
        >
          <Typography variant="h5" sx={{ textAlign: 'center', color: 'white' }}>
            {signUpCTAData.title}
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: 'center', color: 'white' }}>
            {signUpCTAData.subtitle}
          </Typography>
          <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
            <Button
              component="a"
              variant="contained"
              color="secondary"
              size="large"
              href={`${signUpCTAData.buttonLinkJoin}${queryParamsString ? `&${queryParamsString}` : ''}`}
            >
              {signUpCTAData.buttonTextJoin}
            </Button>
            <Link color="#fff" size="large" href={signUpCTAData.buttonLinkSell}>
              {signUpCTAData.buttonTextSell}
            </Link>
          </Stack>
        </Stack>
      </ContentStyle>
    </RootStyle>
  );
};

export default SignUpCTA;
