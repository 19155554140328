import PropTypes from 'prop-types';

// Components
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

// Constants
import WP_ROUTES from 'constants/wordpress-routes';
import { HOMEPAGE_BANNER_UNREGISTERED_REF } from 'constants/route-refs';

// Hooks
import useReferrals from 'hooks/useReferrals';

const BannerBg = '/assets/images/homepage/banner-BG.webp';

const bannerData = {
  id: 1,
  h1: 'A whole new way to wholesale',
  subtitle: 'Source stock directly from over 6,500 independent brands',
  buttonTextBuy: 'REGISTER TO BUY',
  buttonLinkBuy: `${WP_ROUTES.register}?ref=${HOMEPAGE_BANNER_UNREGISTERED_REF}`,
  buttonTextSell: 'REGISTER TO SELL',
  buttonLinkSell: `${WP_ROUTES.registerWholesaler}?ref=${HOMEPAGE_BANNER_UNREGISTERED_REF}`,
  imageWidth: '100vw',
  imageHeight: '280px',
  imageSizes: '100vw',
  imageSrc: BannerBg,
  imageSrcSet: BannerBg,
};

const Banner = ({ loading = false }) => {
  const theme = useTheme();
  const { queryParamsString } = useReferrals();

  const styles = {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100vw',
      height: '38vh',
      background: `url(${BannerBg}) no-repeat center center / cover`,
      backgroundSize: ['cover', null, null, null, 'cover'],
    },
    container: {
      display: 'inline-block',
      width: '100%',
      textAlign: 'center',
    },
    container__Images: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: '362px',
    },
    heroTitle: {
      fontSize: [22, 28, 28, 40, 50, 60],
      fontWeight: 400,
      letterSpacing: 'heading',
      lineHeight: [1.4, null, null, null, null, null, 1.57],
      '@media only screen and (min-height: 720px) and (max-height: 760px)': {
        fontSize: 40,
      },
    },
    desc: {
      fontSize: [15, 16, 15, 16, 16, 16, 16],
      lineHeight: [1.53, 1.53, 1.53, 2, 2.4, 2, 2.48],
      marginTop: 0.5,
      marginBottom: 0.5,
    },
    button: {
      size: 'small',
      maxWidth: '300px',
      mt: 2,
      '@media screen and (max-width: 425px)': {
        mb: 1,
      },
      '@media screen and (max-width: 768px)': {
        size: 'large',
      },
    },
  };

  return (
    <Box
      sx={{
        ...styles.root,
        [theme.breakpoints.down('md')]: {
          mt: 'initial',
        },
        [theme.breakpoints.down('sm')]: {
          px: 1,
        },
      }}
    >
      <Box sx={styles.container}>
        {loading ? (
          <Skeleton variant="rectangular" animation="wave" sx={styles.container__Images} />
        ) : (
          <>
            <Typography component="h1" variant="h2" color="white" sx={styles.heroTitle}>
              {bannerData.h1}
            </Typography>
            <Typography component="p" variant="h5" color="white" sx={styles.desc}>
              {bannerData.subtitle}
            </Typography>
            <Stack
              direction="row"
              spacing={{ xs: 0.5, sm: 2 }}
              sx={{ mt: { xs: 1, sm: 2 }, display: 'flex', justifyContent: 'center', textAlign: 'center' }}
            >
              <Button
                variant="contained"
                color="secondary"
                component="a"
                href={`${bannerData.buttonLinkBuy}${queryParamsString ? `&${queryParamsString}` : ''}`}
              >
                {bannerData.buttonTextBuy}
              </Button>
              <Button variant="contained" color="primary" component="a" href={bannerData.buttonLinkSell}>
                {bannerData.buttonTextSell}
              </Button>
            </Stack>
          </>
        )}
      </Box>
    </Box>
  );
};

Banner.propTypes = {
  loading: PropTypes.bool,
};

export default Banner;
