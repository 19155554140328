import PropTypes from 'prop-types';
import Slider from 'react-slick';

// material-ui
import Box from '@mui/material/Box';

// custom cmps
import Link from 'components/Link/Link';
import BrandCard from 'components/Brand/BrandCard';
import { NewCarouselArrows } from 'components/carousel';

// hooks
import useAuth from 'hooks/useAuth';
import useCardsPerSlide from 'hooks/useCardsPerSlide';
import useResponsive from 'hooks/useResponsive';
import { useRef } from 'react';
import { useTheme } from '@mui/material/styles';

// consts
const BRAND_CARD_WIDTH = 314;

const styles = {
  typography: {
    marginBottom: '3rem',
    marginLeft: '65px',
  },
  groupedBrands: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-evenly',
    gap: '0.75rem',
    padding: '2px',
    mt: 1,
  },
  nextIcon: {
    fontSize: '70px',
  },
  prevIcon: {
    fontSize: '70px',
  },
};

const GroupedBrandCardsSkeletons = ({ brandsPerPage }) => {
  return (
    <Box data-testid="grouped-skeletons" sx={{ ...styles.groupedBrands }}>
      {brandsPerPage && [...Array(brandsPerPage).keys()].map((i) => <BrandCard isLoading forCarousel key={i} />)}
    </Box>
  );
};

const BrandCardGlideCarousel = ({ brands, title, isLoading, viewMoreUrl, sx, showMoreUrl, carouselSettings }) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const brandsPerPage = useCardsPerSlide();

  const carouselRef = useRef(null);

  const isTablet = useResponsive('down', 'md');

  return (
    isAuthenticated && (
      <Box sx={{ ...sx }}>
        <Box
          display="flex"
          alignItems={isTablet ? 'flex-start' : 'center'}
          justifyContent="space-between"
          sx={{
            [theme.breakpoints.down('sm')]: {
              ml: 1,
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              [theme.breakpoints.up('sm')]: { ml: '5px' },
              [theme.breakpoints.down('sm')]: {
                textAlign: 'left',
                maxWidth: BRAND_CARD_WIDTH,
              },
            }}
          >
            {title}
          </Box>
          {showMoreUrl && (viewMoreUrl || isAuthenticated) && !isTablet && (
            <Link
              data-testid="view-all-link"
              target="_blank"
              rel="noreferrer"
              color="primary"
              type="inline"
              variant="body2"
              href={viewMoreUrl || `/store/${brands?.slug}`}
              sx={{
                mr: '65px',
                [theme.breakpoints.down('xl')]: {
                  mr: 2.825,
                },
              }}
            >
              View All
            </Link>
          )}
        </Box>
        <Box data-testid="content-wrapper" sx={{ position: 'relative' }}>
          {isLoading ? (
            <GroupedBrandCardsSkeletons brandsPerPage={brandsPerPage} data-testid="grouped-skeleton-brand-cards" />
          ) : (
            <NewCarouselArrows
              onNext={() => carouselRef.current?.slickNext()}
              onPrevious={() => carouselRef.current?.slickPrev()}
            >
              <Slider ref={carouselRef} {...carouselSettings}>
                {Array.isArray(brands) &&
                  brands?.slice(0, 16)?.map((brand, index) => (
                    <Box key={brand.id} sx={{ px: 0.5, py: 2 }}>
                      <BrandCard key={brand.id} brand={brand} index={index} />
                    </Box>
                  ))}
              </Slider>
            </NewCarouselArrows>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {showMoreUrl && (viewMoreUrl || isAuthenticated) && isTablet && (
            <Link
              data-testid="view-more-link"
              color="primary"
              type="inline"
              variant="body2"
              href={viewMoreUrl || `/store/${brands?.slug}`}
              sx={{
                mt: 2,
              }}
              passHref
            >
              View More
            </Link>
          )}
        </Box>
      </Box>
    )
  );
};

BrandCardGlideCarousel.propTypes = {
  brands: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      minOrder: PropTypes.number,
      thumbnailUrl: PropTypes.string,
    })
  ),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isLoading: PropTypes.bool,
  viewMoreUrl: PropTypes.string,
  sx: PropTypes.shape({}),
  showMoreUrl: PropTypes.bool,
  carouselSettings: PropTypes.shape({}).isRequired,
};

GroupedBrandCardsSkeletons.propTypes = {
  brandsPerPage: PropTypes.number.isRequired,
};

export default BrandCardGlideCarousel;
