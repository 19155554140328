import { DEFAULT_SORT_BY_VALUE } from 'constants/listing-defaults';
import useSWR from 'swr';
import { bffFetcher } from 'utils/functions';
import useAuth from './useAuth';

const MAX_PRODUCTS = 16;

const useNewInSection = ({ limit = 4, isRemainingProducts = false, offset = 4 } = {}) => {
  const auth = useAuth();

  const shippingCountryCode = auth?.user?.shippingCountry;

  const params = new URLSearchParams({
    sortBy: DEFAULT_SORT_BY_VALUE,
    limit: isRemainingProducts ? Number(MAX_PRODUCTS - Number(offset)) : limit,
    ...(isRemainingProducts && { offset }),
    distinctBrands: 'true',
    shippingCountryCode,
  });

  const { data, error, isValidating } = useSWR(`/products?${params}`, bffFetcher);

  return {
    data,
    error,
    isLoading: isValidating,
  };
};

export default useNewInSection;
