import useSWR from 'swr';

/** Utils / Const */
import { bffFetcher } from 'utils/functions';

const useDiscovers = () => {
  const { data: discovers, error } = useSWR('/discovers', bffFetcher);

  return {
    error,
    discovers,
    isLoading: !error && !discovers,
  };
};

export default useDiscovers;
