import { useEffect, useState } from 'react';
import useResponsive from 'hooks/useResponsive';

const useCardsPerSlide = () => {
  const [cardsPerSlide, setCardsPerSlide] = useState(1);

  const isMobile = useResponsive('down', 'sm');
  const isTablet = useResponsive('between', undefined, 'sm', 'md');
  const isSmallDesktop = useResponsive('between', undefined, 'md', 'lg');

  useEffect(() => {
    if (isMobile) {
      setCardsPerSlide(2);
    } else if (isTablet) {
      setCardsPerSlide(1);
    } else if (isSmallDesktop) {
      setCardsPerSlide(3);
    } else {
      setCardsPerSlide(4);
    }
  }, [isMobile, isTablet, isSmallDesktop]);

  return cardsPerSlide;
};

export default useCardsPerSlide;
