import React, { useRef } from 'react';
import Slider from 'react-slick';

// material
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

// other
import PropTypes from 'prop-types';

// custom cmps
import ProductCard from 'components/Product/ProductCard';
import { CarouselDots, NewCarouselArrows } from 'components/carousel';

// hooks
import useAuth from 'hooks/useAuth';
import { useTheme } from '@mui/material/styles';
import useCardsPerSlide from 'hooks/useCardsPerSlide';
import useResponsive from 'hooks/useResponsive';

/** Providers */
import AddToCartPopoverProvider from 'provider/AddToCartPopoverProvider';

const styles = {
  typography: {
    // marginBottom: '3rem',
    marginLeft: '65px',
  },
  groupedProducts: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'space-evenly',
    gap: '0.75rem',
    padding: '2px',
    // mt: 1,
  },
  nextIcon: {
    fontSize: '70px',
  },
  prevIcon: {
    fontSize: '70px',
  },
};

// TODO: keeping it just for now, can be removed if not required
const defaultCarouselSettings = {
  arrows: false,
  lazyLoad: false,
  swipeToSlide: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  centerMode: false,
  centerPadding: '0px',
  adaptiveHeight: true,
  dots: true,
  infinite: true,
  responsive: [
    {
      breakpoint: 1100,
      settings: { slidesToShow: 3, slidesToScroll: 3 },
    },
    {
      breakpoint: 959,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        ...CarouselDots({
          sx: { mt: 3, margin: '0px' },
        }),
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        ...CarouselDots({
          sx: { mt: 3, margin: '0px' },
        }),
      },
    },
  ],
};

const GroupedProductCardsSkeleton = ({ productsPerPage }) => (
  <Box data-testid="grouped-skeletons" sx={{ ...styles.groupedProducts }}>
    {productsPerPage &&
      [...Array(productsPerPage).keys()].map((i, index) => <ProductCard loading key={i} index={index} />)}
  </Box>
);

const productCardGlideCarouselDefaultPropsSx = {};
const productCardGlideCarouselDefaultPropsProducts = [];

const ProductCardGlideCarousel = ({
  products = productCardGlideCarouselDefaultPropsProducts,
  isLoading = false,
  sx = productCardGlideCarouselDefaultPropsSx,
  title = '',
  viewAllUrl = '',
  carouselSettings = defaultCarouselSettings,
  page = 'for-you',
}) => {
  const theme = useTheme();
  const { isAuthenticated } = useAuth();
  const productsPerPage = useCardsPerSlide();
  const carouselRef = useRef(null);

  const isTablet = useResponsive('down', 'md');

  const getMarginBottomValue = () => {
    if (page !== 'cart') {
      return '50px';
    }
    if (isTablet) {
      return '40px';
    }
    return '10px';
  };

  const marginBottomValue = getMarginBottomValue();

  return (
    <Box sx={{ ...sx }}>
      <Box
        display="flex"
        alignItems={isTablet ? 'flex-start' : 'center'}
        justifyContent="space-between"
        sx={{
          [theme.breakpoints.down('sm')]: {
            ml: 1,
            flexDirection: 'column',
          },
        }}
      >
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: { ml: '5px' },
            [theme.breakpoints.down('sm')]: {
              textAlign: 'left',
              maxWidth: '314',
            },
          }}
        >
          {title}
        </Box>

        {(viewAllUrl || isAuthenticated) && !isTablet && (
          <Link
            data-testid="view-all-link"
            target="_blank"
            rel="noreferrer"
            color="primary"
            type="inline"
            variant="body2"
            href={viewAllUrl || `/store/${products?.[0]?.brandSlug}`}
            sx={{
              mr: '65px',
              [theme.breakpoints.down('xl')]: {
                mr: 2.825,
              },
            }}
          >
            View All
          </Link>
        )}
      </Box>

      <Box data-testid="content-wrapper" sx={{ position: 'relative', marginBottom: marginBottomValue }}>
        {isLoading ? (
          <GroupedProductCardsSkeleton productsPerPage={productsPerPage} />
        ) : (
          <NewCarouselArrows
            onNext={() => carouselRef.current?.slickNext()}
            onPrevious={() => carouselRef.current?.slickPrev()}
          >
            <AddToCartPopoverProvider>
              <Slider ref={carouselRef} adaptiveHeight="true" {...carouselSettings}>
                {Array.isArray(products) &&
                  products.slice(0, 16).map((item, index) => (
                    <Box key={item.id} sx={{ px: 0.5, pt: isTablet ? 0 : 2, pb: isTablet ? 1 : 2 }}>
                      <ProductCard key={item.id} product={item} index={index} />
                    </Box>
                  ))}
              </Slider>
            </AddToCartPopoverProvider>
          </NewCarouselArrows>
        )}
      </Box>

      <Box display="flex" alignItems="center" justifyContent="center">
        {(viewAllUrl || isAuthenticated) && isTablet && (
          <Link
            data-testid="view-all-link"
            color="primary"
            type="inline"
            variant="body2"
            href={viewAllUrl || `/store/${products[0]?.brandSlug}`}
            sx={{
              mr: 0,
              mt: '-10px',
            }}
          >
            View All
          </Link>
        )}
      </Box>
    </Box>
  );
};

ProductCardGlideCarousel.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      slug: PropTypes.string,
      brandSlug: PropTypes.string.isRequired,
      country: PropTypes.string,
      description: PropTypes.string,
      minOrder: PropTypes.number,
      thumbnailUrl: PropTypes.string,
      products: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  isLoading: PropTypes.bool,
  sx: PropTypes.shape({}),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  viewAllUrl: PropTypes.string,
  page: PropTypes.string,
  carouselSettings: PropTypes.shape({}),
};

GroupedProductCardsSkeleton.propTypes = {
  productsPerPage: PropTypes.number,
};

export default ProductCardGlideCarousel;
