import PropTypes from 'prop-types';

// MAterial-UI
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useResponsive from 'hooks/useResponsive';

// Hooks
import useDiscovers from 'hooks/useDiscovers';

const discoversListDefaultPropSx = {};

const DiscoversList = ({ sx = discoversListDefaultPropSx, title }) => {
  const theme = useTheme();
  const { discovers, isLoading } = useDiscovers();
  const isTablet = useResponsive('down', 'md');

  const componentStyles = isTablet
    ? {
        grid: {},
        loadingNameWidth: 200,
        textAlign: 'center',
        loadingChipArraySize: 3,
      }
    : {
        grid: { mb: 1 },
        loadingNameWidth: 300,
        textAlign: 'initial',
        loadingChipArraySize: 10,
      };

  const RootStyle = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    backgroundColor: 'theme.palette.background.default',
  }));

  return (
    <RootStyle>
      <Container maxWidth="xl">
        <Box sx={{ mb: { xs: 3, md: 2 } }}>
          <Typography
            variant="h3"
            sx={{ textAlign: 'center', fontSize: { xs: 24, md: 34 }, fontWeight: { xs: 'bold' } }}
          >
            {title}
          </Typography>
        </Box>
        <Grid container sx={{ ...sx }}>
          <Grid
            xs={12}
            item
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            {...componentStyles.grid}
          >
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}
            >
              {isLoading
                ? [...Array(componentStyles.loadingChipArraySize).keys()].map((i) => (
                    <Skeleton key={`discover-chip-${i}`} sx={{ mx: 0.5, borderRadius: 5 }} width={90} height={50} />
                  ))
                : Array.isArray(discovers) &&
                  discovers
                    ?.slice(0, 15)
                    .map((chip) => (
                      <Chip
                        component="a"
                        variant="outlined"
                        label={chip.Name}
                        sx={{ mx: 0.5, my: 0.25 }}
                        key={`chip-discover-${chip.Slug}`}
                        href={`/discover/${chip.Slug}`}
                        clickable
                      />
                    ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
};

DiscoversList.propTypes = {
  sx: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
};

export default DiscoversList;
