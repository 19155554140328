import PropTypes from 'prop-types';

// Material-UI
import { useTheme } from '@mui/material/styles';

// API
import apiFactory from 'services/api/axios';
import { logger } from 'services/api/logger';

// Components
import HomePageSEO from 'components/Seo/HomePageSeo';

// Hooks
import useAuth from 'hooks/useAuth';
import { FullWidthLayout } from 'layouts';

// Utils
import { extractBlogPostRss } from 'utils/functions';
import useCampaignBanner from 'hooks/useCampaignBanner';

// Sections
import RecentlyAdded from 'components/Carousels/home/RecentlyAdded';
import DiscoversList from 'sections/home/discoversList';
import CampaignBanner from 'sections/Campaigns/campaignBanner';
import BlogPostSection from 'sections/home/BlogPostSection';
import FAQHome from 'sections/home/FAQ';
import Banner from 'sections/home/banner';
import Benefits from 'sections/home/benefits';
import CategoriesSection from 'sections/home/categorySection';
import MoversShakersSection from 'components/Carousels/common/MoversShakersSection/MoversShakersSection';
import NewInSection from 'components/Carousels/common/NewInSection/NewInSection';
import PressReleases from 'sections/home/pressReleases';
import SignUpCTA from 'sections/ctas/SignUpCTA';
import OrganizationSchemaSeo from 'components/Seo/OrganizationSchemaSeo';
import { productCarouselSettings } from 'utils/carousels';
import Page from 'components/Page';

// ----------------------------------------------------------------------
const PageWrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <Page
      sx={{
        [theme.breakpoints.down('sm')]: {
          mx: '-16px',
        },
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.background.default,
      }}
    >
      {children}
    </Page>
  );
};

const homeDefaultPropBlogPostData = [
  {
    id: -1,
    name: '',
    url: '',
  },
];

// ----------------------------------------------------------------------

const Home = ({ blogPostData = homeDefaultPropBlogPostData }) => {
  const { isAuthenticated } = useAuth();
  const { campaignBanner } = useCampaignBanner();

  if (isAuthenticated && campaignBanner) {
    return (
      <PageWrapper>
        <OrganizationSchemaSeo />
        <CategoriesSection />
        <CampaignBanner />
        <NewInSection showOnTab="products" currentTab="products" carouselSettings={productCarouselSettings} />
        <MoversShakersSection currentTab="brands" showOnTab="brands" carouselSettings={productCarouselSettings} />
        <PressReleases />
        <BlogPostSection blogPostData={blogPostData} />
      </PageWrapper>
    );
  }
  if (!isAuthenticated && campaignBanner) {
    return (
      <PageWrapper>
        <OrganizationSchemaSeo />
        <HomePageSEO />
        <Banner />
        <Benefits />
        <CampaignBanner />
        <CategoriesSection />
        <RecentlyAdded />
        <PressReleases />
        <DiscoversList title="Trending Searches" />
        <FAQHome />
        <BlogPostSection blogPostData={blogPostData} />
        <SignUpCTA />
      </PageWrapper>
    );
  }
  if (isAuthenticated && !campaignBanner) {
    return (
      <PageWrapper>
        <OrganizationSchemaSeo />
        <CategoriesSection />
        <NewInSection showOnTab="products" currentTab="products" carouselSettings={productCarouselSettings} />
        <MoversShakersSection currentTab="brands" showOnTab="brands" carouselSettings={productCarouselSettings} />
        <PressReleases />
        <BlogPostSection blogPostData={blogPostData} />
      </PageWrapper>
    );
  }
  return (
    <PageWrapper>
      <HomePageSEO />
      <OrganizationSchemaSeo />
      <Banner />
      <Benefits />
      <CategoriesSection />
      <RecentlyAdded />
      <PressReleases />
      <DiscoversList title="Trending Searches" />
      <FAQHome />
      <BlogPostSection blogPostData={blogPostData} />
      <SignUpCTA />
    </PageWrapper>
  );
};

Home.getLayout = FullWidthLayout;

Home.propTypes = {
  blogPostData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      url: PropTypes.string,
    })
  ),
};

export const getServerSideProps = async ({ req }) => {
  const currentUser = req.cookies.user ? JSON.parse(req.cookies.user) : null;
  if (currentUser && currentUser.userType === 'Retailer') {
    return {
      redirect: {
        permanent: false,
        destination: '/for-you',
      },
    };
  }

  let blogPostData = [];
  const baseURL = 'https://www.creoate.com/blog/';
  const blogPostRssCall = apiFactory({ baseURL, headers: { Accept: 'application/xml' } });

  try {
    const blogPostResponse = await blogPostRssCall.get('/tag/selling-wholesale/rss.xml');
    const blogPostResponseData = await blogPostResponse.data;

    blogPostData = extractBlogPostRss(blogPostResponseData);

    return {
      props: {
        blogPostData,
      },
    };
  } catch (error) {
    logger.error({ instance: 'blogPostData', type: 'getServerSideProps' }, error);

    return {
      props: {
        blogPostData: [],
      },
    };
  }
};

export default Home;
