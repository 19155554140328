import Markdown from 'markdown-it';

/** Hooks */
import useCampaignBanner from 'hooks/useCampaignBanner';

// Material-UI
import Box from '@mui/material/Box';
import Link from 'components/Link/Link';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/router';

const RootStyle = styled('div', { shouldForwardProp: (prop) => prop !== 'campaignBanner' })(
  ({ theme, campaignBanner }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100vw',
    height: '60vh',
    backgroundImage: `url(${campaignBanner?.desktop_image?.url})`,
    backgroundColor: `#${campaignBanner?.background_color}`,
    backgroundSize: ['cover', null, null, null, 'cover'],
    backgroundPosition: 'center',
    marginBottom: '1rem',
    [theme.breakpoints.up('lg')]: {
      marginBottom: '2rem',
    },
    [theme.breakpoints.down('md')]: {
      mt: 'initial',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${campaignBanner?.mobile_Image?.url})`,
      px: 1,
    },
    '@media screen and (max-width: 425px)': {
      height: '425px',
    },
    '@media screen and (max-width: 375px)': {
      height: '375px',
    },
    '@media screen and (max-width: 320px)': {
      height: '320px',
    },
    '@media screen and (max-width: 768px)': {
      height: '400px',
    },
    '@media screen and (min-width: 1366px)': {
      height: '500px',
    },
    '@media screen and (min-width: 1440px)': {
      height: '650px',
    },
    '@media screen and (min-width: 2500px)': {
      height: '800px',
    },
  })
);

const Content = styled(Box, { shouldForwardProp: (prop) => prop !== 'campaignBanner' })(({ campaignBanner }) => ({
  display: 'inline-block',
  width: '100%',
  textAlign: campaignBanner?.Alignment,
}));

const H1 = styled(Typography)(() => ({
  fontSize: [22, 28, 28, 40, 50, 60],
  fontWeight: 400,
  letterSpacing: 'heading',
  lineHeight: [1.4, null, null, null, null, null, 1.57],
  '@media only screen and (min-height: 720px) and (max-height: 760px)': {
    fontSize: 40,
  },
}));

const Description = styled(Typography)(() => ({
  fontSize: [15, 16, 15, 24, 24, 24, 24],
  lineHeight: [1.53, 1.53, 1.53, 2, 2.4, 2, 2.48],
  marginTop: 0.5,
  marginBottom: 0.5,
}));

const CampaignButton = styled(Button)(() => ({
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  ml: 'auto',
  mr: 'auto',
  maxWidth: '300px',
  mt: 2,
  '@media screen and (max-width: 425px)': {
    mb: 1,
  },
}));

// ----------------------------------------------------------------------

const markdown = new Markdown();

const CampaignBanner = () => {
  const { campaignBanner, isLoading } = useCampaignBanner();

  const router = useRouter();

  return isLoading ? (
    <Skeleton
      variant="rectangular"
      animation="wave"
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', width: '100vw', height: '375vh' }}
    />
  ) : (
    <Link href={campaignBanner?.banner_link?.url || ''} target="_blank" rel="noopener noreferrer" underline="none">
      <RootStyle campaignBanner={campaignBanner}>
        <Content campaignBanner={campaignBanner}>
          <H1
            component="h2"
            variant={campaignBanner?.title_variant}
            color={campaignBanner?.title_color}
            dangerouslySetInnerHTML={{
              __html: campaignBanner.title ? markdown.render(campaignBanner.title) : '',
            }}
          />
          <Description
            component="p"
            variant={campaignBanner?.subtitle_variant}
            color={campaignBanner?.subtitle_color}
            dangerouslySetInnerHTML={{
              __html: campaignBanner.subtitle ? markdown.render(campaignBanner.subtitle) : '',
            }}
          />
          {campaignBanner.buttons && (
            <Stack
              spacing={{ xs: 0.5, sm: 2 }}
              {...(campaignBanner.buttons_align && { direction: campaignBanner.buttons_align })}
              sx={{ mt: { xs: 1, sm: 2 }, display: 'flex', justifyContent: 'center', textAlign: 'center' }}
            >
              {campaignBanner.buttons.map((button) => (
                <CampaignButton
                  key={button.id}
                  size={button.size}
                  color={button.color}
                  variant={button.variant}
                  onClick={() => router.push(button.url)}
                >
                  {button.label}
                </CampaignButton>
              ))}
            </Stack>
          )}
        </Content>
      </RootStyle>
    </Link>
  );
};

export default CampaignBanner;
